export const GET_PRODUCERS_REQ = 'GET_PRODUCERS_REQ'
export const GET_PRODUCERS_RES_SUCCESS = 'GET_PRODUCERS_RES_SUCCESS'
export const GET_PRODUCERS_RES_WARNING = 'GET_PRODUCERS_RES_WARNING'
export const GET_PRODUCERS_RES_ERROR = 'GET_PRODUCERS_RES_ERROR'

export const GET_TIMERS_REQ = 'GET_TIMERS_REQ'
export const GET_TIMERS_RES_SUCCESS = 'GET_TIMERS_RES_SUCCESS'
export const GET_TIMERS_RES_WARNING = 'GET_TIMERS_RES_WARNING'
export const GET_TIMERS_RES_ERROR = 'GET_TIMERS_RES_ERROR'

export const GET_FAVORITES_REQ = 'GET_FAVORITES_REQ'
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS'
export const ADD_FAVORITE = 'ADD_FAVORITE'
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE'

export const RESET_ERRORS = 'RESET_ERRORS'

export const API_ENDPOINT = '/'